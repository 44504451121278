import { useContext, useEffect } from 'react';
import './App.css';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Dashboard from './Component/Dashboard/Dashboard';
import Nav from './Layout/Nav';
import { useDispatch } from 'react-redux';
import { login } from './Redux/slices/UserSlice';
import EditItem from './Component/Dashboard/EditItem';
import { MantineProvider, Button } from '@mantine/core';
import Login from './Component/Login/Login'
import 'react-toastify/dist/ReactToastify.css';
import Protectpages from './Component/ProtectPage'
import Category from './Component/Category/Category';
import SubCategory from './Component/SubCategory/SubCategory';
import Color from './Component/Color/Color';
import Banner from './Component/Banner/Banner';
import HomeVideo from './Component/HomeVideo/HomeVideo';
import Notification from './Component/Notification/Notification';
import ContactUs from './Component/ContactUs/ContactUs';
import Branch from './Component/Branch/Branch';
import Users from './Component/Users/Users';
import HomeSetup from './Component/HomeSetup/HomeSetup';
import BannerCategory from './Component/BannerCategory/BannerCategory';
import Blog from './Component/Blog/Blog';
import EditBlog from './Component/Blog/EditBlog';
import NewBlog from './Component/Blog/NewBlog';
import OnBoard from './Component/OnBoard/OnBoard';
import OnBoardTable from './Component/OnBoard/OnBoardTable';
import EditBoard from './Component/OnBoard/EditBoard';
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import TermsandConditions from './Component/TermsandConditions/TermsandConditions';
import NewTerms from './Component/TermsandConditions/NewTerms';
import EditTerms from './Component/TermsandConditions/EditTerms';
import Msg from './Component/Mssg/Msg'
function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    const usertoken = localStorage.getItem('token');
    if (usertoken) {
      try {
        const parsedToken = JSON.parse(usertoken);
        const userName = localStorage.getItem('userName');
        const firebaseToken = localStorage.getItem('firebaseToken');
        dispatch(login(parsedToken, userName,firebaseToken));
      } catch (error) {
        console.error('Error parsing token:', error);
      }
    }

  }, [dispatch]);
  return (
    <MantineProvider>
    <div className="App">
      <BrowserRouter>
        <Nav />
        <Routes>
        <Route element={<Protectpages />}>
          <Route path="/" element={<Dashboard />} />
          <Route path='/edit-item/:id' element={<EditItem/>}/>
          <Route path='/category' element={<Category/>}/>
          <Route path='/subCategory/:id' element={<SubCategory/>}/>
          <Route path='/color' element={<Color/>}/>
          <Route path='/banner' element={<Banner/>}/>
          <Route path='/bannerWithCategory' element={<BannerCategory/>}/>
          <Route path='/video' element={<HomeVideo/>}/>
          <Route path='/notification' element={<Notification/>}/>
<Route path='/contactus' element={<ContactUs/>}/>
<Route path='/Branch' element={<Branch/>}/>
<Route path='/users' element={<Users/>}/>
<Route path='/setup' element={<HomeSetup/>}/>
<Route path='/blog' element={<Blog/>}/>
<Route path='/EditBlog/:id' element={<EditBlog/>}/>
<Route path='/newBlog' element={<NewBlog/>}/>
<Route path='/onBoard' element={<OnBoardTable/>}/>
<Route path='/EditBoard/:id' element={<EditBoard/>}/>
<Route path='/Add-onboard' element={<OnBoard/>}/>
<Route path='/TermsandConditions' element={<TermsandConditions/>}/>
<Route path='/newTerms' element={<NewTerms/>}/>
<Route path='/edit-Terms/:id' element={<EditTerms/>}/>
<Route path="/message" element={<Msg />} />

          </Route>
          <Route path="/login" element={<Login />} />

        </Routes>
      </BrowserRouter>
    </div>
    </MantineProvider>
  );
}

export default App;
