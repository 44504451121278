/* import React,{useState,useRef} from 'react'
import styles from '../../Styles/onboard.module.css'
import { Col, Row } from 'react-bootstrap'
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify'
import { useSelector } from 'react-redux';
import Sidebar from '../../Layout/Sidebar';
import uploadimg from "../../assets/images/upload.jpg";

const OnBoard = () => {
    const [valuee, setValue] = React.useState();
    const [valuee1, setValue1] = React.useState();

    const [inputData, setInputData] = useState([
        {

            title: '',
            desc: '',
img:''

        }
    ]);

    const handleServiceChange = (e, index) => {
        const { name, value } = e.target;
        const list = [...inputData];
        list[index][name] = value;
        setInputData(list);
    };
    const handleServiceRemove = (index) => {
        const list = [...inputData];
        list.splice(index, 1);
        setInputData(list);
    };

    const handleServiceAdd = () => {
        setInputData([...inputData,
        {
            title: '',
            desc: '',
            img:''
        }
        ]);
    };
    const addFile = useRef(null);
    const addFileInput = useRef(null);
    const imageContentRef = useRef(null);
    const imageFirmRef = useRef(null);
    const [imageUrl, setImage] = useState(null);
    function handleLogo() {
      let inputFileEvent = document.querySelector(".input-file-js");
      inputFileEvent.click();
    }
    let previewUploadImage = (e) => {
      let file = e.target.files[0];
      if (!file) {
        return;
      }
      let preViewLink = URL.createObjectURL(file);
      setImage(preViewLink);
      setInputData((prevValue) => {
        return {
          ...prevValue,
          img: file,
        };
      });
    };
    function finalize(e) {
        e.preventDefault();

      
    }
    console.log(valuee,valuee1,'hhgg')

  return (
    <>
    <section className={`${styles.home}`}>
        <Sidebar active="onBoard" />
        <div className={`${styles.homeContainer}`}>

    <div>
        <div className={`${styles.date__body}`}>
    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
        <Form.Label>From</Form.Label>
        <Form.Control type="date" onChange={(e)=>setValue(e.target.value)} />
      </Form.Group>
       <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
        <Form.Label>To</Form.Label>
        <Form.Control type="date" onChange={(e)=>setValue1(e.target.value)} />
      </Form.Group>
      </div>
                             
                            {inputData.map((singleService, index) => (
                                <>
                                    <div className={`${styles.info}`} key={index}>
                                        <div className={`${styles.info__delete}`}>
                                            <h3 className={`${styles.item__index}`}>{("Item")} {index + 1}</h3>
                                            {inputData.length !== 1 && (
                                                <button
                                                    type="button"
                                                    onClick={() => handleServiceRemove(index)}
                                                    className={`${styles.removebtn}`}
                                                >
                                                    <span>{("Remove")}</span>
                                                </button>
                                            )}
                                        </div>
                                        <div className={`${styles.field}`}>
                                            <Row className={`${styles.one}`}>
                                                <Col>
                                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                        <Form.Label className={`${styles.label}`}>Title</Form.Label>
                                                        <Form.Control type="text"
                                                            placeholder="title of promotion"
                                                            name='title'
                                                            value={singleService.title}
                                                            onChange={(e) => handleServiceChange(e, index)}
                                                            className={`${styles.info__input}`}
                                                        />
                                                    </Form.Group>
                                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                        <Form.Label className={`${styles.label}`}>Description</Form.Label>
                                                        <Form.Control as="textarea" rows={3}
                                                            placeholder="Description of promotion"
                                                            name='desc'
                                                            value={singleService.desc}
                                                            onChange={(e) => handleServiceChange(e, index)}
                                                            className={`${styles.info__input}`}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col>
                                                <div className={`${styles.im}`}>
                        <input
                          className={`${styles.fileImg}  input-file-js`}
                          ref={(e) => {
                            addFileInput.current = e;
                          }}
                          id="input-file"
                          name="img"
                          type="file"
                          onChange={(e) => {
                            previewUploadImage(e);
                          }}
                        />
                        {imageUrl == null ? (
                          <>
                            <div
                              ref={addFile}
                              onClick={() => {
                                handleLogo();
                              }}
                            >
                              <img
                                alt=""
                                src={uploadimg}
                                ref={imageFirmRef}
                                className={styles.uploadimges}
                              />
                            </div>
                          </>
                        ) : (
                          <div
                            ref={addFile}
                            onClick={() => {
                              handleLogo();
                            }}
                          >
                            <img
                              className={`${styles.img}`}
                              ref={imageContentRef}
                              src={imageUrl}
                              alt=""
                            />
                          </div>
                        )}
                      </div>
                                                </Col>
                                            </Row>
                                           
                                               
                                          
                                        </div>
                                        
                                    </div>
                                    {inputData.length - 1 === index && inputData.length < 4 && (
                                        <button
                                            type="button"
                                            onClick={handleServiceAdd}
                                            className={`${styles.addbtn}`}
                                        >
                                            <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                                                <path d="M22.75 3.5H5.25C4.78587 3.5 4.34075 3.68437 4.01256 4.01256C3.68437 4.34075 3.5 4.78587 3.5 5.25V22.75C3.5 23.2141 3.68437 23.6592 4.01256 23.9874C4.34075 24.3156 4.78587 24.5 5.25 24.5H22.75C23.2141 24.5 23.6592 24.3156 23.9874 23.9874C24.3156 23.6592 24.5 23.2141 24.5 22.75V5.25C24.5 4.78587 24.3156 4.34075 23.9874 4.01256C23.6592 3.68437 23.2141 3.5 22.75 3.5ZM20.125 14.875H14.875V20.125C14.875 20.3571 14.7828 20.5796 14.6187 20.7437C14.4546 20.9078 14.2321 21 14 21C13.7679 21 13.5454 20.9078 13.3813 20.7437C13.2172 20.5796 13.125 20.3571 13.125 20.125V14.875H7.875C7.64294 14.875 7.42038 14.7828 7.25628 14.6187C7.09219 14.4546 7 14.2321 7 14C7 13.7679 7.09219 13.5454 7.25628 13.3813C7.42038 13.2172 7.64294 13.125 7.875 13.125H13.125V7.875C13.125 7.64294 13.2172 7.42038 13.3813 7.25628C13.5454 7.09219 13.7679 7 14 7C14.2321 7 14.4546 7.09219 14.6187 7.25628C14.7828 7.42038 14.875 7.64294 14.875 7.875V13.125H20.125C20.3571 13.125 20.5796 13.2172 20.7437 13.3813C20.9078 13.5454 21 13.7679 21 14C21 14.2321 20.9078 14.4546 20.7437 14.6187C20.5796 14.7828 20.3571 14.875 20.125 14.875Z" fill="white" />
                                            </svg>
                                            <span> {("Add new item")}</span>
                                        </button>
                                    )}
                                </>
                            ))}
                            <button type='submit' className={`${styles.collect}`} onClick={(e) => finalize(e)}>{("Finish and Create")}</button>
                        </div>
                        </div>
    </section>
    </>
  )
}

export default OnBoard */
/* import React, { useState } from 'react';
import styles from '../../Styles/onboard.module.css';
import { Col, Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import Sidebar from '../../Layout/Sidebar';
import uploadimg from '../../assets/images/upload.jpg';

const OnBoard = () => {
  const [formData, setFormData] = useState({
    fromDate: '',
    toDate: '',
    services: [
      {
        title: '',
        desc: '',
        img: null,
      },
    ],
  });

  const handleServiceChange = (e, index) => {
    const { name, value } = e.target;
    const updatedServices = [...formData.services];
    updatedServices[index][name] = value;
    setFormData((prevState) => ({
      ...prevState,
      services: updatedServices,
    }));
  };

  const handleServiceRemove = (index) => {
    const updatedServices = [...formData.services];
    updatedServices.splice(index, 1);
    setFormData((prevState) => ({
      ...prevState,
      services: updatedServices,
    }));
  };

  const handleServiceAdd = () => {
    setFormData((prevState) => ({
      ...prevState,
      services: [
        ...prevState.services,
        {
          title: '',
          desc: '',
          img: null,
        },
      ],
    }));
  };

  const handleImageUpload = (e, index) => {
    const file = e.target.files[0];
    const updatedServices = [...formData.services];
    updatedServices[index].img = file;
    setFormData((prevState) => ({
      ...prevState,
      services: updatedServices,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission, e.g., send data to server
    console.log(formData);
  };

  return (
    <>
      <section className={`${styles.home}`}>
        <Sidebar active="onBoard" />
        <div className={`${styles.homeContainer}`}>
          <form onSubmit={handleSubmit}>
            <div className={`${styles.date__body}`}>
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label>From</Form.Label>
                <Form.Control
                  type="date"
                  value={formData.fromDate}
                  onChange={(e) =>
                    setFormData((prevState) => ({
                      ...prevState,
                      fromDate: e.target.value,
                    }))
                  }
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label>To</Form.Label>
                <Form.Control
                  type="date"
                  value={formData.toDate}
                  onChange={(e) =>
                    setFormData((prevState) => ({
                      ...prevState,
                      toDate: e.target.value,
                    }))
                  }
                />
              </Form.Group>
            </div>

            {formData.services.map((service, index) => (
              <div className={`${styles.info}`} key={index}>
                <div className={`${styles.info__delete}`}>
                  <h3 className={`${styles.item__index}`}>Item {index + 1}</h3>
                  {formData.services.length !== 1 && (
                    <button
                      type="button"
                      onClick={() => handleServiceRemove(index)}
                      className={`${styles.removebtn}`}
                    >
                      <span>Remove</span>
                    </button>
                  )}
                </div>
                <div className={`${styles.field}`}>
                  <Row className={`${styles.one}`}>
                    <Col>
                      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label className={`${styles.label}`}>Title</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="title of promotion"
                          name="title"
                          value={service.title}
                          onChange={(e) => handleServiceChange(e, index)}
                          className={`${styles.info__input}`}
                        />
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label className={`${styles.label}`}>Description</Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={3}
                          placeholder="Description of promotion"
                          name="desc"
                          value={service.desc}
                          onChange={(e) => handleServiceChange(e, index)}
                          className={`${styles.info__input}`}
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <div className={`${styles.im}`}>
                        <input
                          className={`${styles.fileImg} input-file-js`}
                          type="file"
                          name="img"
                          onChange={(e) => handleImageUpload(e, index)}
                        />
                        {service.img ? (
                          <img className={`${styles.img}`} src={URL.createObjectURL(service.img)} alt="" />
                        ) : (
                          <div
                            onClick={() => {
                              document.querySelector('.input-file-js').click();
                            }}
                          >
                            <img alt="" src={uploadimg} className={styles.uploadimges} />
                          </div>
                        )}
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            ))}

            <button type="button" onClick={handleServiceAdd} className={`${styles.addbtn}`}>
              <span>Add new item</span>
            </button>

            <button type="submit" className={`${styles.collect}`}>
              Finish and Create
            </button>
          </form>
        </div>
      </section>
    </>
  );
};

export default OnBoard; */

import React, { useState, useEffect, useRef } from "react";
import styles from "../../Styles/onboard.module.css";
import { Col, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { useSelector } from "react-redux";
import Sidebar from "../../Layout/Sidebar";
import uploadimg from "../../assets/images/upload.jpg";
import uploadimg1 from "../../assets/images/user.png";
import Environment from "../../config";
import { Parallax, Autoplay, EffectCreative, Pagination, Navigation} from 'swiper/modules';

import { Swiper, SwiperSlide } from 'swiper/react';

const OnBoard = () => {
  const { token } = useSelector((state) => state.user);
  const [valuee, setValue] = React.useState();
  const [valuee1, setValue1] = React.useState();
  const [formData, setFormData] = useState({
    fromDate: "",
    toDate: "",
    services: [
      {
        title: "",
        desc: "",
        img: null,
      },
    ],
  });
console.log(formData.services[0].img)
  const handleServiceChange = (e, index) => {
    const { name, value } = e.target;
    const updatedServices = [...formData.services];
    updatedServices[index][name] = value;
    setFormData((prevState) => ({
      ...prevState,
      services: updatedServices,
    }));
  };

  const handleServiceRemove = (index) => {
    const updatedServices = [...formData.services];
    updatedServices.splice(index, 1);
    setFormData((prevState) => ({
      ...prevState,
      services: updatedServices,
    }));
  };

  const handleServiceAdd = () => {
    setFormData((prevState) => ({
      ...prevState,
      services: [
        ...prevState.services,
        {
          title: "",
          desc: "",
          img: null,
        },
      ],
    }));
  };

  const handleImageUpload = (e, index) => {
    const file = e.target.files[0];
    const updatedServices = [...formData.services];
    updatedServices[index].img = file;
    setFormData((prevState) => ({
      ...prevState,
      services: updatedServices,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
    .post(`${Environment.baseURL}/api/admin/FlashSale/addFlashSale`, 
      {
        "dateFrom": valuee,
        "dateTo": valuee1
      }, {
      headers: {    
          Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })
    .then((response) => {
      console.log(response);
      if(response.data.success){
        const formDataa = new FormData();
    formDataa.append('token', token);
  formDataa.append('Id',response.data.data)
    if (formData.services && formData.services.length > 0) {
      const titles = formData.services.map((service) => service.title);
      const descriptions = formData.services.map((service) => service.desc);
  const zft = formData?.services[0]?.img
  console.log(zft)
        formDataa.append(`PicTitle`, titles); 
        formDataa.append(`PicDescription`,descriptions);
        formDataa.append(`FlashSaleImages`,zft);

        

    }
   
   for (let [key, value] of formDataa) {
      console.log(`${key}: ${value}`);
    }
  
  
    axios
      .post(`${Environment.baseURL2}/api/FlashSale/addFlashSale`, formDataa, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
       if(response.data.success){
        setFormData((prevState) => ({
          ...prevState,
          services: [
            {
              title: "",
              desc: "",
              img: null,
            },
          ],
        }))
       }
      })
      .catch((err) => {
        console.log(err.response.data);
      });
      }
    })
    .catch((err) => {
      console.log(err.response.data);
    });
  /*   const formDataa = new FormData();
    formDataa.append('token', token);
    formDataa.append('DateFrom', valuee);
    formDataa.append('DateTo', valuee1);
  
    if (formData.services && formData.services.length > 0) {
      const titles = formData.services.map((service) => service.title);
      const descriptions = formData.services.map((service) => service.desc);
      const images = formData.services.map((service) => service.img);
  console.log(JSON.stringify(images),'imaaaaa')
     
        formDataa.append(`Details.PicTitle`, titles); 
        formDataa.append(`Details.PicDescription`,descriptions);
        for (const image of images) {
          formDataa.append('Details.FlashSaleImages', image);
        } 

    }
   
   for (let [key, value] of formDataa) {
      console.log(`${key}: ${value}`);
    }
  
  
    axios
      .post(`${Environment.baseURL2}/api/FlashSale/addFlashSale`, formDataa, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        console.log(response);
      })
      .catch((err) => {
        console.log(err.response.data);
      }); */
  };


  

  return (
    <>
      <section className={`${styles.home}`}>
        <Sidebar active="onBoard" />
        <div className={`${styles.homeContainer}`}>
          <form onSubmit={handleSubmit}>
            <div className={`${styles.date__body}`}>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>From</Form.Label>
                <Form.Control
                  type="date"
                  value={valuee}
                  onChange={(e) =>
                    setValue(e.target.value)
                  }
                />
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>To</Form.Label>
                <Form.Control
                  type="date"
                  value={valuee1}
                  onChange={(e) =>
                    setValue1(e.target.value)
                  }
                />
              </Form.Group>
            </div>

            {formData.services.map((service, index) => (
              <div className={`${styles.info}`} key={index}>
                <div className={`${styles.info__delete}`}>
                  <h3 className={`${styles.item__index}`}>Item {index + 1}</h3>
                  {formData.services.length !== 1 && (
                    <button
                      type="button"
                      onClick={() => handleServiceRemove(index)}
                      className={`${styles.removebtn}`}
                    >
                      <span>Remove</span>
                    </button>
                  )}
                </div>
                <div className={`${styles.field}`}>
                  <Row className={`${styles.one}`}>
                    <Col>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label className={`${styles.label}`}>
                          Title
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="title of promotion"
                          name="title"
                          value={service.title}
                          onChange={(e) => handleServiceChange(e, index)}
                          className={`${styles.info__input}`}
                        />
                      </Form.Group>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label className={`${styles.label}`}>
                          Description
                        </Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={3}
                          placeholder="Description of promotion"
                          name="desc"
                          value={service.desc}
                          onChange={(e) => handleServiceChange(e, index)}
                          className={`${styles.info__input}`}
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <div className={`${styles.im}`}>
                        <div
                          onClick={() => {
                            document
                              .querySelector(`.input-file-js-${index}`)
                              .click();
                          }}
                        >
                          {service.img ? (
                            <img
                              className={`${styles.img}`}
                              src={URL.createObjectURL(service.img)}
                              alt=""
                            />
                          ) : (
                            <img
                              alt=""
                              src={uploadimg}
                              className={styles.uploadimges}
                            />
                          )}
                        </div>
                        <input
                          className={`${styles.fileImg} input-file-js-${index}`}
                          type="file"
                          name="img"
                          style={{ display: "none" }}
                          onChange={(e) => handleImageUpload(e, index)}
                        />
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            ))}

           

            <button type="submit" className={`${styles.collect}`}>
              Finish and Create
            </button>
          </form>
        </div>
      </section>

       
   
    </>
  );
};

export default OnBoard;
