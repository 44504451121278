import React, { useState, useRef, useEffect } from "react";
import { Select, TextInput, Textarea } from "@mantine/core";
import styles from '../../Styles/contactUs.module.css'
import Sidebar from '../../Layout/Sidebar'
import { useSelector } from "react-redux";
import axios from "axios";
import Environment from '../../config';
import { Button } from '@mantine/core';
import { Col, Row } from "react-bootstrap";
import { mutate } from "swr";
import { toast } from 'react-toastify'
import { ToastContainer } from "react-toastify";
import JoditEditor from "jodit-react";
import TableTerms from "./TableTerms";
export const editorConfig = {
    readonly: false,
    toolbar: true,
    spellcheck: true,
    language: "en",
    toolbarButtonSize: "medium",
    // toolbarAdaptive: false,
    showCharsCounter: true,
    showWordsCounter: true,
    showXPathInStatusbar: false,
    askBeforePasteHTML: true,
    askBeforePasteFromWord: true,
    //defaultActionOnPaste: "insert_clear_html",
    // buttons: buttons,
    uploader: {
      insertImageAsBase64URI: true,
    },
    width: 800,
    minHeight: 400,
    controls: {
      font: {
        command: "fontname",
        list: {
          "'Open Sans',sans-serif": "Open Sans",
          "Helvetica,sans-serif": "Helvetica",
          "Arial,Helvetica,sans-serif": "Arial",
          "Georgia,serif": "Georgia",
          "Impact,Charcoal,sans-serif": "Impact",
          "Tahoma,Geneva,sans-serif": "Tahoma",
          "'Times New Roman',Times,serif": "Times New Roman",
          "Verdana,Geneva,sans-serif": "Verdana",
          "Consolas,monaco,monospace": "Consolas",
        },
      },
    },
  };
const NewTerms = () => {
    const { token } = useSelector((state) => state.user);
    const [selectedOption, setSelectedOption] = useState();
    const [selectLang, setSelect] = useState([]);
    const [selectTerms, setSelectTerms] = useState([]);
    const [selectedOptionTerms, setSelectedOptionTerms] = useState();
    const [initialContent, setInitialContent] = useState();
    const [selectOOption, setSelectOOption] = useState();
    useEffect(() => {
        axios
            .get(
                `${Environment.baseURL}/api/admin/AdminOptions/getLanguage`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            )
            .then((response) => {
                setSelect(response.data.data);
                const filteredData = response.data.data.filter(item => item.defaultFlage === 1);
                setSelectedOption(filteredData.length > 0 ? filteredData[0].code : null);
            })
            .catch((err) => {
                console.log(err.response);
            });


            axios
            .get(
                `${Environment.baseURL}/api/admin/OurPolicy/getPrivacyCodes`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            )
            .then((response) => {
                setSelectTerms(response.data.data)
                console.log(response.data.data)
            })
            .catch((err) => {
                console.log(err.response);
            });

    }, []);
    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
    };
    const handleOptionChangeTerms = (event) => {
        setSelectedOptionTerms(event.target.value);
    };
    console.log(selectTerms)
    function submitDetails(e) {
        e.preventDefault();
        if (initialContent=='<p><br></p>'){
toast.error('please you must add privact text')
        }else{
            const reqData = {
                "languageCode": selectedOption,
          "privacyCode": selectedOptionTerms,
          "privacyText": initialContent
            
                };
            
                
             
                axios
                    .post(`${Environment.baseURL}/api/admin/OurPolicy/managePolicyByLang`, reqData, {
                        headers: {
                            Authorization: `Bearer ${token}`,
                            'Content-Type': 'application/json'
                        }
                    })
                    .then((response) => {
                        console.log(response)
                 
                  if(response.data.success){
        
                        toast.success('Added Terms and Conditions');
                        setInitialContent('')
                        setSelectedOptionTerms()
                        setSelectedOption()
                        window.location.href='/TermsandConditions'
                  }
                    
                    })
                    .catch((err) => {
                        toast.error(err);
                    });
        }
      

    }
  return (
    <> 
     <section className={`${styles.home}`}>
        <Sidebar active="TermsandConditions" />
        <div className={`${styles.homeContainer}`}>
            <h3 className="text-align-center mb-5">Terms and Conditions Information</h3>
            <Row>
                <Col>
        <div >
              <select
                  value={selectedOption}
                  onChange={handleOptionChange}
                  className={`${styles.select}`}
              >
                  <option>Choose Language</option>

                  {selectLang.map((option) => (
                      <option key={option.code} value={option.code}>
                          {option.name}
                      </option>
                  ))}
              </select>
              <select
                  value={selectedOptionTerms}
                  onChange={handleOptionChangeTerms}
                  className={`${styles.select} mt-3`}
              >
                  <option>Choose Terms and Conditions</option>

                  {selectTerms.map((option) => (
                      <option key={option.id} value={option.id}>
                          {option.name}
                      </option>
                  ))}
              </select>
                  <>
                     
                  </>
          </div> 
          
          </Col>
          <Col>
          <div
                  className="App"
                  style={{ maxWidth: editorConfig.width, margin: "0 auto" }}
                >
                  <p>Main of Terms and Conditions</p>
                  <JoditEditor
                    value={initialContent}
                    config={editorConfig}
                    onChange={(value) => setInitialContent(value)}
                    onBlur={(value, event) => console.log(event)}
                  />
                </div>
                <div className={`${styles.sub__btn}`}>
                  <Button type="button" onClick={submitDetails}>Submit Terms and Conditions</Button>
              </div>
          </Col>
          </Row>
        </div>
        <ToastContainer/>
        </section>
    </>
  )
}

export default NewTerms