import React,{useState,useRef,useEffect} from 'react'
import Sidebar from '../../Layout/Sidebar'
import styles from '../../Styles/Notification.module.css'
import { Row ,Col} from 'react-bootstrap'
import { useForm } from '@mantine/form';
import {Button, TextInput,Textarea} from '@mantine/core';
import { Select } from '@mantine/core';
import uploadimg from "../../assets/images/upload.jpg";
import axios from "axios";
import { useSelector } from "react-redux";
import Environment from '../../config';
import { toast } from 'react-toastify'
import noPermission from '../../assets/images/noPermision.png'
const Notification = () => {
    const [selectedOption, setSelectedOption] = useState(``);
    const [value, setValue] = useState('');
    const [items,setItems] = useState(null)
    const [SelectedItem,setSelectedItem] = useState(null)
    const [dataCode,setDataCode] = useState()
    const [notiCode,setNotiCode] = useState([])
    const [codeWithImageFlageOne, setCodeWithImageFlageOne] = useState(null);
    const [codeWithItemFlageOne, setCodeWithItemFlageOne] = useState(null);
    const { token } = useSelector((state) => state.user);
    const [notificationpermision, setnotificationPermision] = useState(
      localStorage.getItem("notificationPremision")
    );
    const form = useForm({
        initialValues: { title: '', body: '' ,mobile:''},
      });
      const [formData, setFormData] = useState({
        img: '',
    })
    const addFile = useRef(null)
    const addFileInput = useRef(null)
    const imageContentRef = useRef(null);
    const imageFirmRef = useRef(null);
    const [imageUrl, setImage] = useState(null)
    function handleLogo() {
        let inputFileEvent = document.querySelector(".input-file-js")
        inputFileEvent.click()
    }
    let previewUploadImage = (e) => {
        let file = e.target.files[0];
        if (!file) {
            return;
        }
        let preViewLink = URL.createObjectURL(file);
        setImage(preViewLink)
        setFormData(prevValue => {
            return {
                ...prevValue,
                'img': file
            }
        })
    }
    useEffect(() => {
        axios.get(`${Environment.baseURL}/api/admin/BasicData/getItemDropdown`, { 
            headers: {
                "Authorization": `Bearer ${token}`
            }
        })
            .then((response) => {
                
                const itemData = response.data.data.map((item) => ({
                    label: item.itemCode,
                    value: item.itemCode,
                    pikLink:item.pikLink
                  }));
                  setItems(itemData)

            }).catch((err) => { console.log(err.response) })
        
    }, [])
    useEffect(() => {
        axios.get(`${Environment.baseURL}/api/admin/BasicData/getNotificationCodes`, { 
            headers: {
                "Authorization": `Bearer ${token}`
            }
        })
            .then((response) => {
                setNotiCode(response.data.data)
                const objectWithImageFlageOne = response.data.data.find(item => item.imageFlage === 1);
setCodeWithImageFlageOne(objectWithImageFlageOne)
const objectWithItemFlageOne = response.data.data.find(item => item.itemFlage === 1);
setCodeWithItemFlageOne(objectWithItemFlageOne)
                

            }).catch((err) => { console.log(err.response) })
        
    }, [])
    useEffect(() => {
      if(value){
        const selected = items.find(item => item.value === value)
        setSelectedItem(selected)
        console.log(selected,'selected')
      }
        
    }, [value])
    useEffect(() => {
        if(dataCode && form.values.mobile==''){
        axios.post(`${Environment.baseURL}/api/admin/Notification/sendNotificationToAllTopic?DocNo=${dataCode}`, { 
            headers: {
                "Authorization": `Bearer ${token}`
            }
        })
            .then((response) => {
                console.log(response,'done')

            }).catch((err) => { console.log(err.response) })
        }else if(dataCode && form.values.mobile!=''){
          axios.post(`${Environment.baseURL}/api/admin/Notification/sendNotificationToUser`,
             {
            "userMobile":form.values.mobile,
            "docNo": dataCode
          }
          ,{ 
            headers: {
                "Authorization": `Bearer ${token}`
            }
        })
            .then((response) => {
                console.log(response,'doneuser')

            }).catch((err) => { console.log(err.response) })
        }
    }, [dataCode])
    function submitImagesSize(e) {
        e.preventDefault();
        const reqData = {
            MsgType:selectedOption,
            MsgTitle:form.values.title,
            MsgBody:form.values.body,
            ItemCode:value,
            NotificationImage: formData.img
        };
        axios
            .post(`${Environment.baseURL2}/api/Notification/addNotification`, reqData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then((response) => {
                toast.success('Upload Notification Success');
                setDataCode(JSON.parse(response.data.data).data)
                setSelectedOption('')
                setSelectedItem(null)
            })
            .catch((err) => {
                toast.error(err);
            });
    }
    console.log(localStorage.getItem('itemPicpremision'),'ýarab')

      return (
    <>
<section className={`${styles.home}`}>
                <Sidebar active='notification' />
                <div className={`${styles.homeContainer}`}>
                  {
                    notificationpermision==1?
                    <>
                    <h3>Notification Details</h3>
                    <Row>
    <Col>
                    <div className={`${styles.base__body}`}>

    <h6 className={`${styles.text}`}>Message Type</h6>
    <select
                  value={selectedOption}
                  onChange={(e)=>setSelectedOption(e.target.value)}
                  className={`${styles.select}`}
              >
                  <option>Choose Type</option>
                  {
                    notiCode.map(noti=>(
                        <>
                        <option value={noti.code} key={noti.code}>{noti.name}</option>

                        </>
                    ))
                  }

              </select>
              <TextInput mt="sm" label="user's mobile (if you want send message to specific user)" placeholder="user's mobile" {...form.getInputProps('mobile')} />

        <TextInput mt="sm" label="Message title" placeholder="title" {...form.getInputProps('title')} />
        <Textarea
        mt="sm" 
        label="Message body"
         placeholder="body" 
         {...form.getInputProps('body')}
        autosize
        minRows={3}
      />
{
    selectedOption==codeWithItemFlageOne?.code?
    <Select
      label="Select ItemID"
      placeholder="Pick value"
      data={items}
      searchable
      value={value} 
      onChange={setValue}
      mt="sm"
    />
    :
    ''
}

{
    selectedOption==codeWithImageFlageOne?.code?
<>
<hr/>
<h6 className={`${styles.text}`}>Attach image</h6>
<div className={`${styles.im}`}>
                          <input className={`${styles.fileImg}  input-file-js`} ref={(e) => {
                              addFileInput.current = e
                          }} id="input-file" name="img" type="file" onChange={(e) => { previewUploadImage(e) }} />
                          {imageUrl == null ?
                              <>
                                  <div ref={addFile} onClick={() => { handleLogo() }}>
                                     
                                      <img
                        alt=""
                        src={uploadimg}
                        ref={imageFirmRef}
                        className={styles.uploadimges}
                        
                      /> 
                                  </div>
                              </>
                              :
                              <div ref={addFile} onClick={() => { handleLogo() }}>
                                  <img className={`${styles.img}`} ref={imageContentRef} src={imageUrl} alt="" />
                              </div>
                          }
                      </div>
                      </>
                      :
                      ''
}
<div className={`${styles.sub__btn}`}>
<Button type="button" onClick={submitImagesSize}>Submit</Button>
</div>
                    </div>
                    </Col>
                    <Col className={`${styles.show}`}>
  {form.values.title && form.values.body && selectedOption ? (
    SelectedItem || imageUrl ? (
      <div className="card">
        <div className="actions">
          {SelectedItem ? (
            <img
              alt=""
              src={SelectedItem.pikLink}
              className={`${styles.imgnotification}`}
            />
          ) : (
            <img
              alt=""
              src={imageUrl}
              className={`${styles.imgnotification}`}
            />
          )}
        </div>
        <div className="header">
          <span className="icon">
            <svg
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                clipRule="evenodd"
                d="M18 3a1 1 0 00-1.447-.894L8.763 6H5a3 3 0 000 6h.28l1.771 5.316A1 1 0 008 18h1a1 1 0 001-1v-4.382l6.553 3.276A1 1 0 0018 15V3z"
                fillRule="evenodd"
              ></path>
            </svg>
          </span>
          <p className="alert" style={{ width: '250px' }}>
            {form.values.title}
          </p>
        </div>
        <p className="message" style={{ width: '250px' }}>
          {form.values.body}
        </p>
      </div>
    ) : (
      ''
    )
  ) : (
    ''
  )}
</Col>
</Row>
</>
:
<div className={`${styles.nopermision}`}>
  <img alt='' src={noPermission} className={`${styles.nopermision_img}`}/>
  <h2>No access to notification</h2>
  <p>you don't have access to notification !</p>
  </div>
                  }

                </div>
            </section>
    </>
  )
}

export default Notification