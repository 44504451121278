import React, { useState, useEffect ,useRef} from "react";
import { db, storage } from "../../firebase";
import {
  collection,
  addDoc,
  onSnapshot,
  query,
  orderBy,
  Timestamp,
  Firestore,
  deleteDoc
} from "firebase/firestore";
import Sidebar from "../../Layout/Sidebar";
import styles from "../../Styles/msg.module.css";
import { Button, Col, Row, Toast } from "react-bootstrap";
import support from "../../assets/images/support.png";
import Form from "react-bootstrap/Form";
import one from "../../assets/images/client.png";
import three from "../../assets/images/tjree.png";
import { useSelector } from "react-redux";
import {
  doc,
  updateDoc,
  getDoc,
  getDocs,
  where,
  writeBatch,
} from "firebase/firestore";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import uploadimg from '../../assets/images/attach.png'
import ProgressBar from 'react-bootstrap/ProgressBar';
import { toast, ToastContainer } from "react-toastify";
import Environment from "../../config";
import axios from "axios";
import { SegmentedControl } from "@mantine/core";

const Msg = () => {
  const imageUrlPrefix = "https://firebasestorage.googleapis.com";
  const [segmant, setSegmant] = useState("RecentMessages");
  const [imageUpload, setImageUpload] = useState(null);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const { userName, firebaseToken, token, usr } = useSelector(
    (state) => state.user
  );
  const [customerName, setCustomerName] = useState("");
  const [customerPhone, setCustomerPhone] = useState("");
  const currentUser = firebaseToken;
  const fileInputRef = useRef(null);
  const [receiverUser, setReceiverUser] = useState();
  const [messageUser, setMesgUser] = useState([]);
  const [nameUser, setNameUser] = useState();
  const [downImage, setDownImage] = useState(null);
  const[prog,setProg]=useState()
  const[archivedChats,setArchivedChats]=useState([])
  const[archivedId,setArchivedId]=useState()
  const[archivedUser,setArchivedUser]=useState([])
console.log(messageUser)
  useEffect(() => {

    const q = query(collection(db, "messages") , orderBy("date", )) ;
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const messagesData = snapshot.docs.map((doc) => ({
        ...doc.data(),
      }));

      console.log(messagesData, "messagesData");
      const filteredMessages = messagesData.filter(msg =>( msg.agentId == currentUser)|| ( msg.agentId == ''));

      const groupedMessages = filteredMessages.reduce((acc, message) => {
        const { customerId, agentId ,customerName,customerPhone} = message;
        const conversationKey =
        customerId < agentId ? `${customerPhone}_${customerName}` : `${customerPhone}_${customerName}`;

        if (!acc[conversationKey]) {
          acc[conversationKey] = [];
        }
        acc[conversationKey].push(message);

        return acc;
      }, {});
      const messagesArray = Object.entries(groupedMessages).map(
        ([key, messages], index) => {
          return { [`${key}`]: messages };
        }
      );
      console.log(messagesArray, "groupedMessagrfyhtuhrjrytes");
      setMessages(messagesArray);
    });

    return () => {
      unsubscribe();
    };
  }, [currentUser, receiverUser]);
  const handleUserClick = (customerKey, messages) => {
    setNameUser(customerKey);
    setMesgUser(messages); 
    setCustomerPhone(messages[0]?.customerPhone)
    setCustomerName(messages[0]?.customerName)
    console.log(messages,'customerKey')

  };
  const handleUserArchivedClick = (message)=> {
    setNameUser(message.customerData);
    setArchivedId(message.id)
  };
  function convertTimestampToISOString(timestamp) {
    const date = new Date(timestamp.seconds * 1000 + Math.floor(timestamp.nanoseconds / 1000000));
    return date.toISOString();
}
const deleteDocumentsByCustomerId = async (customerId) => {
  try {
    const q = query(collection(db, 'messages'), where('customerId', '==', customerId));
    const querySnapshot = await getDocs(q);
    
    const deletionPromises = querySnapshot.docs.map((doc) => {
      return deleteDoc(doc.ref);
    });

    await Promise.all(deletionPromises);
    console.log('Documents with customerId deleted successfully');
  } catch (error) {
    console.error('Error deleting documents: ', error);
  }
};
const handleArchiveClick= ( messages) => {
const newArray = messages.map(item => ({
    ...item,
    date: convertTimestampToISOString(item.date), // Convert the date
}));
console.log(newArray,'customerKdfhstyjhtghetyjtyjhey')

axios
.post(`${Environment.baseURL}/api/ChatArchive`, 
  newArray
, {
  headers: {
    Authorization: `Bearer ${token}`,
    
  }
})
.then((response) => {
 console.log(response)
 if(response.data.data){
  deleteDocumentsByCustomerId(receiverUser);
  toast.success('This session will be archived for viewing only.')
  setMesgUser([])
 }
})
.catch((err) => {
  toast.error(err);
});

};
  useEffect(() => {
    const selectedMessages = messages.find(
      (customer) => Object.keys(customer)[0] === nameUser
    );
    console.log(nameUser, "n");
    if (selectedMessages) {
      setMesgUser(selectedMessages[nameUser], "ff");
    }
    if(messages.length==0){
      setMesgUser([])
    }
  }, [messages]);
  const handleSend = async (e) => {
    e.preventDefault();
    if (imageUpload != null) {
      const storageRef = ref(storage, imageUpload.name);
      const uploadTask = uploadBytesResumable(storageRef, imageUpload);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setProg("Upload is " + progress.toFixed(2) + "% done");
        
          switch (snapshot.state) {
            case "paused":
              console.log("Upload is paused");
              break;
            case "running":
              console.log("Upload is running");
              break;
          }
        },
        (error) => {
          console.log(error);
        },
        async () => {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          setDownImage(downloadURL);

          const newMessageData = {
            message: downloadURL,
            customerId:  receiverUser,
            agentId: currentUser,
            date: new Date(),
          flag:2,
          adminId: "",
          customerName: customerName,
          customerPhone: customerPhone,
          };

          const messagesRef = collection(db, "messages");

          // Update existing messages with empty agentId
          const querySnapshot = await getDocs(
            query(messagesRef, where("agentId", "==", ""))
          );
          const batch = writeBatch(db);

          querySnapshot.forEach((doc) => {
            batch.update(doc.ref, { agentId: currentUser });
          });

          // Commit the batch update
          await batch.commit();

          // Add the new message to the collection
          await addDoc(messagesRef, {
            ...newMessageData,
          });

          // Clear the input
          setNewMessage("");
          setImageUpload(null);
          setDownImage(null);
        }
      );
    } else {
      const newMessageData = {
        message: newMessage,
        customerId:  receiverUser,
        agentId: currentUser,
        date: new Date(),
        flag:2,
        adminId: "",
customerName: customerName,
customerPhone: customerPhone,

      };
      const messagesRef = collection(db, "messages");
      const querySnapshot = await getDocs(
        query(messagesRef, where("agentId", "==", ""))
      );

      const batch = writeBatch(db);

      querySnapshot.forEach((doc) => {
        batch.update(doc.ref, { agentId: currentUser });
      });

      // Commit the batch update
      await batch.commit();


      await addDoc(messagesRef, {
        ...newMessageData,
      });


      setNewMessage("");
      setImageUpload(null);
      setDownImage(null);
    }
  };
  function formatTimestamp(timestamp) {
    const date = new Date(timestamp.seconds * 1000); 
    const now = new Date();
    const options = { hour: "2-digit", minute: "2-digit" };
    const timeString = date.toLocaleTimeString([], options);
    const diffInDays = Math.floor((now - date) / (1000 * 60 * 60 * 24));
    if (diffInDays === 0) {
      return ` ${timeString}`;
    } else if (diffInDays === 1) {
      return `${timeString}`;
    } else {
      return /* date.toLocaleDateString() + ' at ' + */ timeString;
    }
  }
  function formatMessage(message) {
    const imageUrlPrefix = "https://firebasestorage.googleapis.com";

    if (message.startsWith(imageUrlPrefix)) {
      return (
     
          // eslint-disable-next-line jsx-a11y/img-redundant-alt
          <img
            src={`${message}`}
            alt="Image"
            style={{ width: "40%", height: "auto" }}
            className={`${segmant=='RecentMessages'?styles.send__imge:styles.arcsend__imge}`}
          />
        
      );
    } else {
      return <p>{message}</p>;
    }
  }
  useEffect(() => {
    if(segmant=='ArchivedMessages'){
      setMesgUser([])
     
    axios
      .get(`${Environment.baseURL}/api/ChatArchive/getChatArchives`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setArchivedChats(response.data.data)
      })
      .catch((err) => {
        toast.error(err);
      });
      
       
      }
      if(segmant=='RecentMessages'){
        setArchivedUser([])
      }
  }, [segmant]);
  useEffect(() => {
    if(archivedId)
    axios
      .get(`${Environment.baseURL}/api/ChatArchive/getChat?id=${archivedId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setArchivedUser(response.data.data)
      })
      .catch((err) => {
        toast.error(err);
      });
  }, [archivedId]);
  const formatTime = (timestamp) => {
    const date = new Date(timestamp);
    const options = { hour: 'numeric', minute: 'numeric', hour12: true };
    return date.toLocaleString('en-US', options);
  };
  console.log(archivedUser,'archivedUser')
  return (
    <>
      <section className={`${styles.home}`}>
        <Sidebar active="message" />
        <div className={`${styles.homeContainer}`}>
          <Row className={`row__chats`}>
            <Col xl="3" lg="3">
              <div className={`${styles.msg__side}`}>
                <div className={`${styles.inputBox_container}`}>
                  <svg
                    className={`${styles.search_icon}`}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 48 48"
                    alt="search icon"
                  >
                    <path
                      d="M46.599 46.599a4.498 4.498 0 0 1-6.363 0l-7.941-7.941C29.028 40.749 25.167 42 21 42 9.402 42 0 32.598 0 21S9.402 0 21 0s21 9.402 21 21c0 4.167-1.251 8.028-3.342 11.295l7.941 7.941a4.498 4.498 0 0 1 0 6.363zM21 6C12.717 6 6 12.714 6 21s6.717 15 15 15c8.286 0 15-6.714 15-15S29.286 6 21 6z"
                      className={`${styles.ico}`}
                    ></path>
                  </svg>
                  <input
                    className={`${styles.inputBox}`}
                    id="inputBox"
                    type="text"
                    placeholder="Search...."
                  />
                </div>
                <div  className={`seq d-flex align-items-center justify-content-center mt-3 mb-2`}>
                <SegmentedControl
                  value={segmant}
                  onChange={setSegmant}
                  data={[
                    { label: "Recent Messages", value: "RecentMessages" },
                    { label: "Archived Messages", value: "ArchivedMessages" },
                  ]}
                  className={`${styles.seq}`}
                />
                </div>
              {
                segmant=='RecentMessages'?
                <div>
                {messages.map((customer, index) => {
                  const customerKey = Object.keys(customer)[0]; // e.g., 'customer1'
                  const messages = customer[customerKey]; // Get the array of messages

                  // Get the last message
                  const lastMessage =
                    messages.length > 0
                      ? messages[messages.length - 1].message
                      : "No messages";

                  return (
                    <div
                      className={
                        nameUser == customerKey
                          ? `${styles.chat}`
                          : `${styles.chat1}`
                      }
                      key={index}
                      onClick={() => handleUserClick(customerKey, messages)}
                    >
                      <div
                        className={`${styles.user__chat}`}
                        onClick={() =>
                          setReceiverUser(
                            customer[customerKey].map((u) => u.customerId)[0]
                          )
                        }
                      >
                             <svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" stroke-width="1.5" viewBox="0 0 24 24" fill="none">
<path d="M12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2Z" stroke="#BE8F6E" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M4.271 18.3457C4.271 18.3457 6.50002 15.5 12 15.5C17.5 15.5 19.7291 18.3457 19.7291 18.3457" stroke="#BE8F6E" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M12 12C13.6569 12 15 10.6569 15 9C15 7.34315 13.6569 6 12 6C10.3431 6 9 7.34315 9 9C9 10.6569 10.3431 12 12 12Z" stroke="#BE8F6E" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
                        <div>
                          <h3>{customerKey}</h3>
                          <p>{formatMessage(lastMessage)}</p>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
              :
              <div>
              {
                archivedChats.map(arc=>(
                  <div
                  className={
                    nameUser == arc.id
                      ? `${styles.chat}`
                      : `${styles.chat1}`
                  }
                  onClick={() => handleUserArchivedClick(arc)}
                >
                  <div
                    className={`${styles.user__chat}`}
                  >
                         <svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" stroke-width="1.5" viewBox="0 0 24 24" fill="none">
<path d="M12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2Z" stroke='#777' stroke-linecap="round" stroke-linejoin="round"/>
<path d="M4.271 18.3457C4.271 18.3457 6.50002 15.5 12 15.5C17.5 15.5 19.7291 18.3457 19.7291 18.3457"  stroke-linecap="round" stroke='#777' stroke-linejoin="round"/>
<path d="M12 12C13.6569 12 15 10.6569 15 9C15 7.34315 13.6569 6 12 6C10.3431 6 9 7.34315 9 9C9 10.6569 10.3431 12 12 12Z"  stroke='#777' stroke-linecap="round" stroke-linejoin="round"/>
</svg>
                    <div>
                    <h3>{arc.customerData}</h3>
                      <p>{formatMessage(arc.lastMessage)}</p>
                    </div>
                  </div>
                </div>
                ))
              }
              </div>
              }
               
              </div>
            </Col>
            
            {messageUser.length > 0 ? (
              <Col xl="8" lg="8">
                <div className={`${styles.msg__content}`}>
                  <div className={`${styles.top}`}>
                    <div className={`${styles.user__chat}`}>
                      <div className={`${styles.online}`}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" stroke-width="1.5" viewBox="0 0 24 24" fill="none">
<path d="M12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2Z" stroke="#BE8F6E" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M4.271 18.3457C4.271 18.3457 6.50002 15.5 12 15.5C17.5 15.5 19.7291 18.3457 19.7291 18.3457" stroke="#BE8F6E" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M12 12C13.6569 12 15 10.6569 15 9C15 7.34315 13.6569 6 12 6C10.3431 6 9 7.34315 9 9C9 10.6569 10.3431 12 12 12Z" stroke="#BE8F6E" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
                      </div>
                      <div className={`${styles.end}`}>
                        <h3>{nameUser}</h3>
                        
                      </div>
                    </div>
                    <Button variant="danger" onClick={() => handleArchiveClick(messageUser)}>End Chat</Button>
                  </div>

                  {messageUser.map((msg) =>
                    msg.flag == 2 ? (
                      <div className="mt-5 mb-5" key={msg.id}>
                      <div className={`${styles.send}`}>
                        <div  className={`${segmant=='RecentMessages'? styles.send__body:styles.arcsend__body}`}>
                          <div>
                         
                          {formatMessage(msg.message)}
                          <h6 className={`${styles.seen1}`}>
                            {formatTimestamp(msg.date)}
                          </h6>
                          </div>
                          <img
                            alt=""
                            src={support}
                            className={`${styles.user}`}
                          />
                        </div>
                      </div>
                    </div>
                    ) : (
                    <div className={`${styles.received}`} key={msg.id}>
                    <div className={`${segmant=='RecentMessages'?styles.received__body:styles.arcreceived__body}`}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" stroke-width="1.5" viewBox="0 0 24 24" fill="none">
<path d="M12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2Z" stroke="#BE8F6E" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M4.271 18.3457C4.271 18.3457 6.50002 15.5 12 15.5C17.5 15.5 19.7291 18.3457 19.7291 18.3457" stroke="#BE8F6E" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M12 12C13.6569 12 15 10.6569 15 9C15 7.34315 13.6569 6 12 6C10.3431 6 9 7.34315 9 9C9 10.6569 10.3431 12 12 12Z" stroke="#BE8F6E" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
<div>
                      {formatMessage(msg.message)}
                    
                    <h6 className={`${styles.seen}`}>
                      {formatTimestamp(msg.date)}
                    </h6>
                    </div>
                    </div>
                  </div>  
                    )
                  )}

                  <div className="form__send">
                    <Form onSubmit={handleSend}>
                      <Row className="form__send__row">
                        <Col xl='10'>
                        {
                          imageUpload==null?
                          <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlTextarea1"
                        >
                          <Form.Control
                            as="textarea"
                            rows={2}
                            placeholder="Write your message here...."
                            value={newMessage}
                            onChange={(e) => setNewMessage(e.target.value)}
                          />
                        </Form.Group>
                        :
                        <>
                        <img src={URL.createObjectURL(imageUpload)} style={{ width: '50% '}}/>
                        <p className={`${styles.prog}`}>
                       {prog} 
                        </p>
                        </>
                        }
                    
                      </Col>
                      <Col xl='1'    style={{ width: '40px '}}>
                      <input
                        label="Image"
                        placeholder="Choose image"
                        accept="image/png,image/jpeg"
                        type="file"
                        onChange={(e) => {
                          setImageUpload(e.target.files[0]);
                        }}
                        style={{ display: "none" }}
                        ref={fileInputRef}
                      />
                      <img
    alt=""
    src={uploadimg}
    onClick={() => fileInputRef.current.click()}
    className={styles.uploadimges}
    style={{ width: '40px '}}
  />
                      </Col>
                      <Col xl='1'>
                      <div className={`${styles.btns}`}>
                        <button className={`${styles.send__btn}`} type="submit">
                          Send
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="12"
                            height="12"
                            viewBox="0 0 12 12"
                            fill="none"
                          >
                            <g clip-path="url(#clip0_215_11739)">
                              <path
                                d="M10.1173 2.84329C10.3333 2.24579 9.75428 1.66679 9.15678 1.88329L1.85428 4.52429C1.25478 4.74129 1.18228 5.55929 1.73378 5.87879L4.06478 7.22829L6.14628 5.14679C6.24058 5.05571 6.36688 5.00531 6.49798 5.00645C6.62908 5.00759 6.75448 5.06018 6.84719 5.15288C6.93989 5.24558 6.99247 5.37099 6.99361 5.50209C6.99475 5.63319 6.94436 5.75949 6.85328 5.85379L4.77178 7.93529L6.12178 10.2663C6.44078 10.8178 7.25878 10.7448 7.47578 10.1458L10.1173 2.84329Z"
                                fill="white"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_215_11739">
                                <rect width="12" height="12" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                        </button>
                      </div>
                      </Col>
                      </Row>
                    </Form>
                  </div>
                </div>
              </Col>
            ) : (
              ""
            )}
            {archivedUser.length > 0 ? (
              <Col xl="8" lg="8">
                <div className={`${styles.msg__content}`}>
                  <div className={`${styles.top}`}>
                    <div className={`${styles.user__chat}`}>
                      <div className={`${styles.online}`}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" stroke-width="1.5" viewBox="0 0 24 24" fill="none">
<path d="M12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2Z" stroke="#777" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M4.271 18.3457C4.271 18.3457 6.50002 15.5 12 15.5C17.5 15.5 19.7291 18.3457 19.7291 18.3457" stroke="#777" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M12 12C13.6569 12 15 10.6569 15 9C15 7.34315 13.6569 6 12 6C10.3431 6 9 7.34315 9 9C9 10.6569 10.3431 12 12 12Z" stroke="#777" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
                      </div>
                      <div className={`${styles.end}`}>
                        <h3>{nameUser}</h3>
                        
                      </div>
                    </div>
                  </div>

                  {archivedUser.map((msg) =>
                    msg.flag == 2 ? (
                      <div className="mt-5 mb-5" key={msg.id}>
                      <div className={`${styles.send}`}>
                        <div className={`${segmant=='RecentMessages'? styles.send__body:styles.arcsend__body}`}>
                          <div>
                         
                          {formatMessage(msg.message)}
                          <h6 className={`${styles.seen1}`}>
                            {formatTime(msg.date)}
                          </h6>
                          </div>
                          <img
                            alt=""
                            src={support}
                            className={`${styles.user}`}
                          />
                        </div>
                      </div>
                    </div>
                    ) : (
                    <div className={`${styles.received}`} key={msg.id}>
                    <div className={`${segmant=='RecentMessages'?styles.received__body:styles.arcreceived__body}`}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" stroke-width="1.5" viewBox="0 0 24 24" fill="none">
<path d="M12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2Z" stroke="#777" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M4.271 18.3457C4.271 18.3457 6.50002 15.5 12 15.5C17.5 15.5 19.7291 18.3457 19.7291 18.3457" stroke="#777" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M12 12C13.6569 12 15 10.6569 15 9C15 7.34315 13.6569 6 12 6C10.3431 6 9 7.34315 9 9C9 10.6569 10.3431 12 12 12Z" stroke="#777" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
<div>
                      {formatMessage(msg.message)}
                    
                    <h6 className={`${styles.seen}`}>
                      {formatTime(msg.date)}
                    </h6>
                    </div>
                    </div>
                  </div>  
                    )
                  )}

                
                </div>
              </Col>
            ) : (
              ""
            )}
          </Row>
        </div>
        <ToastContainer/>
      </section>
    </>
  );
};

export default Msg;
