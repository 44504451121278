import React,{useState,useRef,useEffect} from 'react'
import { useDisclosure } from '@mantine/hooks';
import {Modal, Button } from '@mantine/core';
import styles from '../../Styles/dashboard.module.css'
import axios from "axios";
import { useSelector } from "react-redux";
import { toast } from 'react-toastify'
import { ToastContainer } from "react-toastify";
import Environment from '../../config';
import { mutate } from 'swr';
import { TextInput } from "@mantine/core";
const EditLocation = ({data}) => {
  const { token } = useSelector((state) => state.user);
  const [link, setLink] = useState(data.locationLink);
  const [opened, { open, close }] = useDisclosure(false);
console.log(data)
  function handleUpload(e) {
    e.preventDefault();
    const reqData = 
      {
        "branchCode": data.branchCode,
        "locationLink":link,

      }
    axios
        .post(`${Environment.baseURL}/api/admin/BranchLang/manageBranchLocation`, reqData, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        .then((response) => {
          console.log(response)
          mutate([
            `${Environment.baseURL}/api/admin/BranchLang/getAllBranchs`,
            token,
          ]);
          close()
                toast.success('Edit Branch Location successfully');
        })
        .catch((err) => {
            toast.error(err.response.data.message);
            console.log(err)
  
        });
  }
  return (
    <>
<Modal opened={opened} onClose={close} title={` Branch details`} >

                       <TextInput
                          className={`${styles.title} pt-0 mt-0`}
                          label={`Branch Location Link `}
                          value={link}
                          onChange={(event) => setLink(event.currentTarget.value)}
                      />

                    <div className={`${styles.sub__btn}`}>
                    <Button type='button' onClick={handleUpload}>Submit</Button>
                    </div>
      </Modal>
<div className='btn__location'>
      <Button onClick={open} > Edit Branch Location</Button>
      </div>
    </>
  )
}

export default EditLocation