import React, { useState, useEffect, useRef } from "react";
import styles from "../../Styles/onboard.module.css";
import { Col, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { useSelector } from "react-redux";
import Sidebar from "../../Layout/Sidebar";
import uploadimg from "../../assets/images/upload.jpg";
import uploadimg1 from "../../assets/images/user.png";
import Environment from "../../config";
import {
    MRT_GlobalFilterTextField,
    MRT_TableBodyCellValue,
    MRT_TablePagination,
    MRT_ToolbarAlertBanner,
    flexRender,
    useMaterialReactTable,
} from 'material-react-table';
import {
    Box,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import useSWR, { mutate, useSWRConfig } from 'swr'
import { Link } from "react-router-dom";
import {  Button } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import Modal from 'react-bootstrap/Modal';
const OnBoardTable = () => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
      
    const { token } = useSelector((state) => state.user);
const[data,setTableBoard]=useState([])
const [opened, { open, close }] = useDisclosure(false);
const [valuee, setValue] = React.useState();
const [valuee1, setValue1] = React.useState();
const [formData, setFormData] = useState({
  fromDate: "",
  toDate: "",
  services: [
    {
      title: "",
      desc: "",
      img: null,
    },
  ],
});
console.log(formData.services[0].img)
const handleServiceChange = (e, index) => {
  const { name, value } = e.target;
  const updatedServices = [...formData.services];
  updatedServices[index][name] = value;
  setFormData((prevState) => ({
    ...prevState,
    services: updatedServices,
  }));
};

const handleServiceRemove = (index) => {
  const updatedServices = [...formData.services];
  updatedServices.splice(index, 1);
  setFormData((prevState) => ({
    ...prevState,
    services: updatedServices,
  }));
};

const handleServiceAdd = () => {
  setFormData((prevState) => ({
    ...prevState,
    services: [
      ...prevState.services,
      {
        title: "",
        desc: "",
        img: null,
      },
    ],
  }));
};

const handleImageUpload = (e, index) => {
  const file = e.target.files[0];
  const updatedServices = [...formData.services];
  updatedServices[index].img = file;
  setFormData((prevState) => ({
    ...prevState,
    services: updatedServices,
  }));
};

const handleSubmit = (e) => {
  e.preventDefault();
  axios
  .post(`${Environment.baseURL}/api/admin/FlashSale/addFlashSale`, 
    {
      "dateFrom": valuee,
      "dateTo": valuee1
    }, {
    headers: {    
        Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  })
  .then((response) => {
    console.log(response);
    if(response.data.success){
      const formDataa = new FormData();
  formDataa.append('token', token);
formDataa.append('Id',response.data.data)
  if (formData.services && formData.services.length > 0) {
    const titles = formData.services.map((service) => service.title);
    const descriptions = formData.services.map((service) => service.desc);
const zft = formData?.services[0]?.img
console.log(zft)
      formDataa.append(`PicTitle`, titles); 
      formDataa.append(`PicDescription`,descriptions);
      formDataa.append(`FlashSaleImages`,zft);

      setShow(false)

  }
 
 for (let [key, value] of formDataa) {
    console.log(`${key}: ${value}`);
  }


  axios
    .post(`${Environment.baseURL2}/api/FlashSale/addFlashSale`, formDataa, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((response) => {
     if(response.data.success){
      setFormData((prevState) => ({
        ...prevState,
        services: [
          {
            title: "",
            desc: "",
            img: null,
          },
        ],
      }))
     }
    })
    .catch((err) => {
      console.log(err.response.data);
    });
    }
  })
  .catch((err) => {
    console.log(err.response.data);
  });
}
    const fetcher = (args) => {
        const [url, token] = args
        return axios
          .get(url, { headers: { Authorization: `Bearer ${ token }` }
    })
          .then((response) => {
            
            setTableBoard(response.data.data);
            return response.data.data 
          }  
          )
       }
    const { category, error } = useSWR(
      [`${Environment.baseURL}/api/admin/FlashSale/getFlashSaleIndix`, token],
      fetcher, 
    );
    if (error) console.log(error);
    if (data){
      console.log(data, 'swr');
      
    } 
      const columns = [
        {
          accessorKey: "id",
          header: "ID",
        },
        {
          accessorKey: "dateFrom",
          header: "Date Form",
        },
        {
          accessorKey: "dateTo",
          header: "Date To",
        },
        {
            accessorKey: "Action",
            header: "Action",
            columnDefType: "display",
            enableColumnOrdering: 1,
            Cell: ({ renderedCellValue, row, cell }) => (
              <>
               
           <Link to={`/EditBoard/${row.original.id}`} ><Button className={`${styles.subcat__btn}`}>Edit OnBoard </Button></Link>
          
              </>
            ),
          },
     
      ];
      const table = useMaterialReactTable({
        columns,
        data, 
        enableRowSelection: false,
        initialState: {
            pagination: { pageSize: 20, pageIndex: 0 },
            showGlobalFilter: true,
        },
        muiPaginationProps: {
            rowsPerPageOptions: [5, 10, 15],
            variant: 'outlined',
        },
        paginationDisplayMode: 'pages',
    });

    
     
  return (
    <>
 <section className={`${styles.home}`}>
        <Sidebar active="onBoard" />
        <div className={`${styles.homeContainer}`}>
        <Stack sx={{ m: ' 0' }}>
        <Typography variant="h4" className="name">OnBoard details</Typography>
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
            }}
        >

            <MRT_GlobalFilterTextField table={table} />
            <MRT_TablePagination table={table} />
            <div >
            <Button variant="primary" onClick={handleShow}>
            <Link to='/Add-onboard' className="addBoard">
     Add New Board
     </Link>
      </Button>

   {/*    <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Add New Board</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <form onSubmit={handleSubmit}>
            <div className={`${styles.date__body}`}>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>From</Form.Label>
                <Form.Control
                  type="date"
                  value={valuee}
                  onChange={(e) =>
                    setValue(e.target.value)
                  }
                />
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>To</Form.Label>
                <Form.Control
                  type="date"
                  value={valuee1}
                  onChange={(e) =>
                    setValue1(e.target.value)
                  }
                />
              </Form.Group>
            </div>

            {formData.services.map((service, index) => (
              <div className={`${styles.info}`} key={index}>
                <div className={`${styles.info__delete}`}>
                  <h3 className={`${styles.item__index}`}>Item {index + 1}</h3>
                  {formData.services.length !== 1 && (
                    <button
                      type="button"
                      onClick={() => handleServiceRemove(index)}
                      className={`${styles.removebtn}`}
                    >
                      <span>Remove</span>
                    </button>
                  )}
                </div>
                <div className={`${styles.field}`}>
                  <Row className={`${styles.one}`}>
                    <Col>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label className={`${styles.label}`}>
                          Title
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="title of promotion"
                          name="title"
                          value={service.title}
                          onChange={(e) => handleServiceChange(e, index)}
                          className={`${styles.info__input}`}
                        />
                      </Form.Group>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label className={`${styles.label}`}>
                          Description
                        </Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={3}
                          placeholder="Description of promotion"
                          name="desc"
                          value={service.desc}
                          onChange={(e) => handleServiceChange(e, index)}
                          className={`${styles.info__input}`}
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <div className={`${styles.im}`}>
                        <div
                          onClick={() => {
                            document
                              .querySelector(`.input-file-js-${index}`)
                              .click();
                          }}
                        >
                          {service.img ? (
                            <img
                              className={`${styles.img}`}
                              src={URL.createObjectURL(service.img)}
                              alt=""
                            />
                          ) : (
                            <img
                              alt=""
                              src={uploadimg}
                              className={styles.uploadimges}
                            />
                          )}
                        </div>
                        <input
                          className={`${styles.fileImg} input-file-js-${index}`}
                          type="file"
                          name="img"
                          style={{ display: "none" }}
                          onChange={(e) => handleImageUpload(e, index)}
                        />
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            ))}

           

            <button type="submit" className={`${styles.collect}`}>
              Finish and Create
            </button>
          </form>
        </Modal.Body>
</Modal> */}
      </div>
        </Box>
        <TableContainer>
            <Table>
                <TableHead>
                    {table.getHeaderGroups().map((headerGroup) => (
                        <TableRow key={headerGroup.id}>
                            {headerGroup.headers.map((header) => (
                                <TableCell align="center" variant="head" key={header.id}>
                                    {header.isPlaceholder
                                        ? null
                                        : flexRender(
                                            header.column.columnDef.Header ??
                                            header.column.columnDef.header,
                                            header.getContext(),
                                        )}
                                </TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableHead>
                <TableBody>
                    {table.getRowModel().rows.map((row, rowIndex) => (
                        <TableRow key={row.id} selected={row.getIsSelected()}>
                            {row.getVisibleCells().map((cell, _columnIndex) => (
                                <TableCell align="center" variant="body" key={cell.id}>
                                    {/* Use MRT's cell renderer that provides better logic than flexRender */}
                                    <MRT_TableBodyCellValue
                                        cell={cell}
                                        table={table}
                                        staticRowIndex={rowIndex} //just for batch row selection to work
                                    />
                                </TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
        <MRT_ToolbarAlertBanner stackAlertBanner table={table} />
    </Stack>
        </div>
        </section>
    </>
  )
}

export default OnBoardTable