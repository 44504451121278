import React, { useState, useRef, useEffect } from "react";
import { Select, TextInput, Textarea } from "@mantine/core";
import styles from '../../Styles/contactUs.module.css'
import Sidebar from '../../Layout/Sidebar'
import { useSelector } from "react-redux";
import axios from "axios";
import Environment from '../../config';
import { Button } from '@mantine/core';
import { Col, Row } from "react-bootstrap";
import { mutate } from "swr";
import { toast } from 'react-toastify'
import { ToastContainer } from "react-toastify";
import JoditEditor from "jodit-react";
import TableTerms from "./TableTerms";
import { useParams } from "react-router-dom";

export const editorConfig = {
    readonly: false,
    toolbar: true,
    spellcheck: true,
    language: "en",
    toolbarButtonSize: "medium",
    // toolbarAdaptive: false,
    showCharsCounter: true,
    showWordsCounter: true,
    showXPathInStatusbar: false,
    askBeforePasteHTML: true,
    askBeforePasteFromWord: true,
    //defaultActionOnPaste: "insert_clear_html",
    // buttons: buttons,
    uploader: {
      insertImageAsBase64URI: true,
    },
    width: 800,
    minHeight: 400,
    controls: {
      font: {
        command: "fontname",
        list: {
          "'Open Sans',sans-serif": "Open Sans",
          "Helvetica,sans-serif": "Helvetica",
          "Arial,Helvetica,sans-serif": "Arial",
          "Georgia,serif": "Georgia",
          "Impact,Charcoal,sans-serif": "Impact",
          "Tahoma,Geneva,sans-serif": "Tahoma",
          "'Times New Roman',Times,serif": "Times New Roman",
          "Verdana,Geneva,sans-serif": "Verdana",
          "Consolas,monaco,monospace": "Consolas",
        },
      },
    },
  };
const EditTerms = () => {
    const { token } = useSelector((state) => state.user);
const[termsId,setTermId]=useState({})
    const [initialContent, setInitialContent] = useState();
    const termId = useParams();
    const [titleLang, setTitleLang] = useState("");
    const [titleTerm, setTitleTerm] = useState("");

    useEffect(() => {
        axios
            .get(
                `${Environment.baseURL}/api/admin/OurPolicy/getPoliciesById?id=${termId.id}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            )
            .then((response) => {
                setTermId(response.data.data)
                setInitialContent(response.data.data.privacyText)
                setTitleLang(response.data.data.langName)
                setTitleTerm(response.data.data.privacyName)
            })
            .catch((err) => {
                console.log(err.response);
            });


           

    }, [termId.id]);


   function submitDetails(e) {
        e.preventDefault();
        const reqData = {
        "languageCode": termsId.languageCode,
  "privacyCode": termsId.privacyCode,
  "privacyText": initialContent
    
        };
    
        
     
        axios
            .post(`${Environment.baseURL}/api/admin/OurPolicy/managePolicyByLang`, reqData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            })
            .then((response) => {
                console.log(response)
         
          if(response.data.success){

                toast.success('Added Terms and Conditions');

                window.location.href='/TermsandConditions'
          }
            
            })
            .catch((err) => {
                toast.error(err);
            });

    } 
  return (
    <>
     <section className={`${styles.home}`}>
        <Sidebar active="TermsandConditions" />
        <div className={`${styles.homeContainer}`}>
            <h3 className="text-align-center mb-5">Terms and Conditions Information</h3>
            <Row>
                <Col>
        <div >
        <TextInput
                          className={`${styles.title}`}
                          label={`Language Name `}
                          value={titleLang}
                          readOnly
                          
                          
                      />
                        <TextInput
                          className={`${styles.title}`}
                          label={`Privacy Name `}
                          value={titleTerm}
                          readOnly
                          
                      />
                  <>
                     
                  </>
          </div> 
          
          </Col>
          <Col>
          <div
                  className="App"
                  style={{ maxWidth: editorConfig.width, margin: "0 auto" }}
                >
                  <p>Main of Terms and Conditions</p>
                  <JoditEditor
                    value={initialContent}
                    config={editorConfig}
                    onChange={(value) => setInitialContent(value)}
                    onBlur={(value, event) => console.log(event)}
                  />
                </div>
              <div className={`${styles.sub__btn}`}>
                  <Button type="button" onClick={submitDetails}>Submit Terms and Conditions</Button>
              </div> 
          </Col>
          </Row>
        </div>
        <ToastContainer/>
        </section>
    </>
  )
}

export default EditTerms