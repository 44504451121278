import React, { useState, useEffect, useRef } from "react";
import styles from "../../Styles/onboard.module.css";
import { Col, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { useSelector } from "react-redux";
import Sidebar from "../../Layout/Sidebar";
import Environment from "../../config";
import { useParams } from "react-router-dom";
import { Button } from "@mantine/core";
import useSWR, { mutate, useSWRConfig } from "swr";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import uploadimg from "../../assets/images/upload.jpg";

const EditBoard = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(!show);
  const [data, setEditBoard] = useState([]);
  const [boaard, setBoard] = useState([]);

  const { token } = useSelector((state) => state.user);
  const boardId = useParams();
  const fetcher = (args) => {
    const [url, token] = args;
    return axios
      .get(url, { headers: { Authorization: `Bearer ${token}` } })
      .then((response) => {
        setBoard(response.data.data);
        setEditBoard(response.data.data.details);
        return response.data.data.details;
      });
  };
  const { category, error } = useSWR(
    [
      `${Environment.baseURL}/api/admin/FlashSale/manageFlashSale?Id=${boardId.id}`,
      token,
    ],
    fetcher
  );
  if (error) console.log(error);
  if (data) {
    console.log(data, "swr");
  }
  /*  useEffect(() => {
        axios
            .get(
                `${Environment.baseURL}/api/admin/FlashSale/manageFlashSale?Id=${boardId.id}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            )
            .then((response) => {
                setEditBoard(response.data.data.details);
                
            })
            .catch((err) => {
                console.log(err.response);
            });
    }, []); */

  const handleDelete = (id) => {
    axios
      .post(
        `${Environment.baseURL}/api/admin/FlashSale/deleteFlashSaleDetail?DetailId=${id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        toast.success(response.data.data);
        mutate([
          `${Environment.baseURL}/api/admin/FlashSale/manageFlashSale?Id=${boardId.id}`,
          token,
        ]);
      })
      .catch((err) => {
        toast.error(err);
      });
  };
  console.log(boaard);
  const [valuee, setValue] = React.useState();
  const [valuee1, setValue1] = React.useState();
  const [formData, setFormData] = useState({
    fromDate: "",
    toDate: "",
    services: [
      {
        title: "",
        desc: "",
        img: null,
      },
    ],
  });
  console.log(formData.services[0].img);
  const handleServiceChange = (e, index) => {
    const { name, value } = e.target;
    const updatedServices = [...formData.services];
    updatedServices[index][name] = value;
    setFormData((prevState) => ({
      ...prevState,
      services: updatedServices,
    }));
  };

  const handleServiceRemove = (index) => {
    const updatedServices = [...formData.services];
    updatedServices.splice(index, 1);
    setFormData((prevState) => ({
      ...prevState,
      services: updatedServices,
    }));
  };

  const handleServiceAdd = () => {
    setFormData((prevState) => ({
      ...prevState,
      services: [
        ...prevState.services,
        {
          title: "",
          desc: "",
          img: null,
        },
      ],
    }));
  };

  const handleImageUpload = (e, index) => {
    const file = e.target.files[0];
    const updatedServices = [...formData.services];
    updatedServices[index].img = file;
    setFormData((prevState) => ({
      ...prevState,
      services: updatedServices,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
   
          const formDataa = new FormData();
          formDataa.append("token", token);
          formDataa.append("Id", boaard.id);
          if (formData.services && formData.services.length > 0) {
            const titles = formData.services.map((service) => service.title);
            const descriptions = formData.services.map(
              (service) => service.desc
            );
            const zft = formData?.services[0]?.img;
            console.log(zft);
            formDataa.append(`PicTitle`, titles);
            formDataa.append(`PicDescription`, descriptions);
            formDataa.append(`FlashSaleImages`, zft);
          }

          for (let [key, value] of formDataa) {
            console.log(`${key}: ${value}`);
          }

          axios
            .post(
              `${Environment.baseURL2}/api/FlashSale/addFlashSale`,
              formDataa,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            )
            .then((response) => {
              if (response.data.success) {
                setFormData((prevState) => ({
                  ...prevState,
                  services: [
                    {
                      title: "",
                      desc: "",
                      img: null,
                    },
                  ],
                }));
                mutate([
                  `${Environment.baseURL}/api/admin/FlashSale/manageFlashSale?Id=${boardId.id}`,
                  token,
                ]);
              }
            })
            .catch((err) => {
              console.log(err.response.data);
            });
        
     
  };
  return (
    <>
      <section className={`${styles.home}`}>
        <Sidebar active="onBoard" />
        <div className={`${styles.homeContainer}`}>
        <div className={`${styles.collect__body}`}>
          <Button variant="primary" onClick={handleShow}>
             New Board
          </Button>
          </div>
{
  show?

              <form onSubmit={handleSubmit}>
               

                {formData.services.map((service, index) => (
                  <div className={`${styles.info}`} key={index}>
                    <div className={`${styles.info__delete}`}>
                      <h3 className={`${styles.item__index}`}>
                        Item {index + 1}
                      </h3>
                      {formData.services.length !== 1 && (
                        <button
                          type="button"
                          onClick={() => handleServiceRemove(index)}
                          className={`${styles.removebtn}`}
                        >
                          <span>Remove</span>
                        </button>
                      )}
                    </div>
                    <div className={`${styles.field}`}>
                      <Row className={`${styles.one}`}>
                        <Col>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label className={`${styles.label}`}>
                              Title
                            </Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="title of promotion"
                              name="title"
                              value={service.title}
                              onChange={(e) => handleServiceChange(e, index)}
                              className={`${styles.info__input}`}
                            />
                          </Form.Group>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label className={`${styles.label}`}>
                              Description
                            </Form.Label>
                            <Form.Control
                              as="textarea"
                              rows={3}
                              placeholder="Description of promotion"
                              name="desc"
                              value={service.desc}
                              onChange={(e) => handleServiceChange(e, index)}
                              className={`${styles.info__input}`}
                            />
                          </Form.Group>
                        </Col>
                        <Col>
                          <div className={`${styles.im}`}>
                            <div
                              onClick={() => {
                                document
                                  .querySelector(`.input-file-js-${index}`)
                                  .click();
                              }}
                            >
                              {service.img ? (
                                <img
                                  className={`${styles.img}`}
                                  src={URL.createObjectURL(service.img)}
                                  alt=""
                                />
                              ) : (
                                <img
                                  alt=""
                                  src={uploadimg}
                                  className={styles.uploadimges}
                                />
                              )}
                            </div>
                            <input
                              className={`${styles.fileImg} input-file-js-${index}`}
                              type="file"
                              name="img"
                              style={{ display: "none" }}
                              onChange={(e) => handleImageUpload(e, index)}
                            />
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                ))}
<div className={`${styles.collect__body}`}>
                <button type="submit" className={`${styles.collect}`}>
                  Finish and Create
                </button>
                <button type="submit" className={`${styles.collect} `} onClick={handleClose}>
                 close
                </button>
                </div>
              </form>
              :
              ''
           }
          {data.map((eboard) => (
            <>
              <div className={`${styles.info}`} key={eboard.detailsId}>
                <div className={`${styles.field}`}>
                  <Row className={`${styles.one} pt-5`}>
                    <Col>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label className={`${styles.label}`}>
                          Title
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="title of promotion"
                          name="title"
                          value={eboard.picTitle}
                          className={`${styles.info__input}`}
                        />
                      </Form.Group>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label className={`${styles.label}`}>
                          Description
                        </Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={3}
                          placeholder="Description of promotion"
                          name="desc"
                          value={eboard.picDescription}
                          className={`${styles.info__input}`}
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <div className={`${styles.im}`}>
                        <img
                          alt=""
                          src={eboard.picLink}
                          className={styles.uploadimges}
                        />
                      </div>
                    </Col>
                  </Row>
                </div>
                <div className={`${styles.delete__btn}`}>
                  <Button onClick={() => handleDelete(eboard.detailsId)}>
                    Delete OnBoard
                  </Button>
                </div>
              </div>
            </>
          ))}
        </div>
      </section>
    </>
  );
};

export default EditBoard;
