import React, { useState, useEffect } from "react";
import {
  MRT_GlobalFilterTextField,
  MRT_TableBodyCellValue,
  MRT_TablePagination,
  MRT_ToolbarAlertBanner,
  flexRender,
  useMaterialReactTable,
} from "material-react-table";
import {
  Box,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import axios from "axios";
import { useSelector } from "react-redux";
import style from '../../Styles/category.module.css'
import EditCategory from "./EditCategory";
import {  Button } from '@mantine/core';
import Environment from '../../config';
import noImage from '../../assets/images/24.png'
import useSWR, { mutate, useSWRConfig } from 'swr'
const TableCategory = () => {
  const [data, setCategory] = useState([]);
  const { token } = useSelector((state) => state.user);
  const [imagepermision, setItemPermision] = useState(
    localStorage.getItem("categoryPicpremision")
  );
/*   useEffect(() => {
    axios
      .get(
        `${Environment.baseURL}/api/admin/Category/getCategories`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setCategory(response.data.data);
      })
      .catch((err) => {
        console.log(err.response);
      });
  }, []); */
  const fetcher = (args) => {
    const [url, token] = args
    return axios
      .get(url, { headers: { Authorization: `Bearer ${ token }` }
})
      .then((response) => {
        
        setCategory(response.data.data);
        return response.data.data 
      }  
      )
   }
const { category, error } = useSWR(
  [`${Environment.baseURL}/api/admin/Category/getCategories`, token],
  fetcher, 
);
if (error) console.log(error);
if (data){
  console.log(data, 'swr');
  
} 
  const columns = [
    {
      accessorKey: "code",
      header: "category Code",
    },
    {
      accessorKey: "name",
      header: "Name Ar",
    },
    {
      accessorKey: "nameE",
      header: "Name En",
    },
    {
      accessorKey: "picLink",
      header: "Image",
      columnDefType: "display",
      enableColumnOrdering: 1,
      Cell: ({ renderedCellValue, row, cell }) => (
        <>
          <div>
            {
              row.original.picLink?
              <img
              alt=""
              src={row.original.picLink}
              className={`${style.category__img}`}
            />
            :
            <img
              alt=""
              src={noImage}
              className={`${style.category__img}`}
            />
            }
            
          </div>
        </>
      ),
    },
    {
      accessorKey: "Action",
      header: "Action",
      columnDefType: "display",
      enableColumnOrdering: 1,
      Cell: ({ renderedCellValue, row, cell }) => (
        <>
          {
            imagepermision==1?
            <EditCategory data={row.original}/>
:
''
          }
        <div>
          
     <Link to={`/subCategory/${row.original.code}`} ><Button className={`${style.subcat__btn} name`}>SubCategory </Button></Link>
     </div>
        </>
      ),
    },
  ];
  const table = useMaterialReactTable({
    columns,
    data, 
    enableRowSelection: false,
    initialState: {
        pagination: { pageSize: 20, pageIndex: 0 },
        showGlobalFilter: true,
    },
    muiPaginationProps: {
        rowsPerPageOptions: [5, 10, 15],
        variant: 'outlined',
    },
    paginationDisplayMode: 'pages',
});
  return (
    <Stack sx={{ m: ' 0' }}>
        <Typography variant="h4" className="name">Category details</Typography>
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
            }}
        >

            <MRT_GlobalFilterTextField table={table} />
            <MRT_TablePagination table={table} />
        </Box>
        <TableContainer>
            <Table>
                <TableHead className="name">
                    {table.getHeaderGroups().map((headerGroup) => (
                        <TableRow key={headerGroup.id}>
                            {headerGroup.headers.map((header) => (
                                <TableCell align="center" variant="head" key={header.id}>
                                    {header.isPlaceholder
                                        ? null
                                        : flexRender(
                                            header.column.columnDef.Header ??
                                            header.column.columnDef.header,
                                            header.getContext(),
                                        )}
                                </TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableHead>
                <TableBody>
                    {table.getRowModel().rows.map((row, rowIndex) => (
                        <TableRow key={row.id} selected={row.getIsSelected()}>
                            {row.getVisibleCells().map((cell, _columnIndex) => (
                                <TableCell align="center" variant="body" key={cell.id}>
                                    {/* Use MRT's cell renderer that provides better logic than flexRender */}
                                    <MRT_TableBodyCellValue
                                        cell={cell}
                                        table={table}
                                        staticRowIndex={rowIndex} //just for batch row selection to work
                                    />
                                </TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
        <MRT_ToolbarAlertBanner stackAlertBanner table={table} />
    </Stack>
);
};

export default TableCategory;
