import React, { useState, useRef, useEffect } from "react";
import { Select, TextInput, Textarea } from "@mantine/core";
import styles from '../../Styles/contactUs.module.css'
import Sidebar from '../../Layout/Sidebar'
import { useSelector } from "react-redux";
import axios from "axios";
import Environment from '../../config';
import { Button } from '@mantine/core';
import { Col, Row } from "react-bootstrap";
import { mutate } from "swr";
import { toast } from 'react-toastify'
import { ToastContainer } from "react-toastify";
import JoditEditor from "jodit-react";
import TableTerms from "./TableTerms";

const TermsandConditions = () => {


  return (
    <>
 <section className={`${styles.home}`}>
        <Sidebar active="TermsandConditions" />
       
        <div className={`${styles.homeContainer}`}>
            <TableTerms/>
           
        </div>
        <ToastContainer/>
        </section>
    </>
  )
}

export default TermsandConditions